import './jquery-global.js';
import '../node_modules/jquery-validation/dist/jquery.validate.min.js';
import '../node_modules/jquery-validation/dist/localization/messages_fr.js';
import '../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.js';
import Blazy from "blazy";
import Slick from "slick-slider";

$(function() {

  const header = $('.header');
  const body = $('body');
  const hamburger = $('.hamburger');
  const navigation = $('.header--nav');

  // Is Mobile ?
  function checkMobile(){
    if (hamburger.css('display') == 'none') {
      hamburger.removeClass('is-active');
      body.removeClass('is-fixed');
      navigation.removeAttr('style');
    }
  }
  checkMobile(); // Au chargement de la page
  $(window).resize(checkMobile); // Au redimensionnement de la page

  // Bouton Hamburger
  $(hamburger, header).click(function(e) {
    e.preventDefault();
    hamburger.toggleClass('is-active');
    body.toggleClass('is-fixed');
  });

  // Lazyload (Media)
  var bLazy = new Blazy();

  // Obfuscation de lien (PR Sculpting)
  $('[data-obf]').each(function() {
    $(this).click(function() {
      let attribute = $(this).data('obf');
      document.location.href = decodeURIComponent(window.atob(attribute));
    });
  });
})
